import React, {useState, useEffect} from 'react';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Beasthood from './components/beasthood';
import './App.css';
import RichBeasthoodTable from './components/richBeasthood';

const App = () => {
  const [items, setItems] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect (() => {
    const getItems =  async () =>{
      const result = await axios.get(
        `https://eco-avenue-359721.wm.r.appspot.com/getBeasthoodListings`
        )
      console.log(result.data);
      setItems(result.data);
      setLoading(false);
    }
    getItems();
    setInterval(getItems, 60000);
  }, []);

  return  ( <div className="container">
    {/* <Alert variant="filled" severity="error" style={{ width: "95vw", margin: "0px auto", maxWidth: 600, border: "none", borderRadius: 0 }}>
        WAGMI Way's floor is not updating as expected. At this time, all other floors are functioning properly. We apologies for the inconvenience!
      </Alert> */}
    <h1>BEASTHOOD<br></br>ANALYTICS</h1>
    <RichBeasthoodTable isLoading={isLoading} items={items}></RichBeasthoodTable>
    {/* <Beasthood isLoading={isLoading} items={items}/> */}
    <h3>POWERED BY SILICON ALLEY</h3>
  </div> );
}

export default App;
