import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';

function createData(image, name, floor) {
    return { image, name, floor };
}

const StyledTableRow = styled(TableRow)(() => ({}));
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        border: "none",
        letterSpacing: "3px",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        fontFamily: "Swiss-Roman",
        letterSpacing: "1.5px",
        backgroundColor: "#e8e6e3",
        border: "none",
        borderTop: "1px #39393926 solid"
    },
}));

const Beasthood = ({ items, isLoading }) => {
    const rows = [
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Wagmi Way" src="https://beasthood.akidcalledbeast.com/assets/pfp/3degen.png" />, "Wagmi Way", items.wagmiWay),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Silicon Alley" src="https://beasthood.akidcalledbeast.com/assets/pfp/18hacker.png" />, "Silicon Alley", items.siliconAlley),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Bullhattan District" src="https://beasthood.akidcalledbeast.com/assets/pfp/1business.png" />, "Bullhattan District", items.bullhattanDistrict),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Nowhere Parks" src="https://beasthood.akidcalledbeast.com/assets/pfp/13loner.png" />, "Nowhere Parks", items.nowhereParks),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Moo Moo Meadows" src="https://beasthood.akidcalledbeast.com/assets/pfp/19wild.png" />, "Moo Moo Meadows", items.mooMooMeadows),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Champion's Court" src="https://beasthood.akidcalledbeast.com/assets/pfp/4athlete.png" />, "Champion's Court", items.championsCourt),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Fort Flex" src="https://beasthood.akidcalledbeast.com/assets/pfp/7hustler.png" />, "Fort Flex", items.fortFlex),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Cloudy Gardens" src="https://beasthood.akidcalledbeast.com/assets/pfp/14daydreamer.png" />, "Cloudy Gardens", items.cloudyGardens),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Trusty Lake Estates" src="https://beasthood.akidcalledbeast.com/assets/pfp/8preppy.png" />, "Trusty Lake Estates", items.trustyLakeEstates),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Beat Street" src="https://beasthood.akidcalledbeast.com/assets/pfp/5musician.png" />, "Beat Street", items.beatStreet),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Studio 66" src="https://beasthood.akidcalledbeast.com/assets/pfp/17popular.png" />, "Studio 66", items.studio66),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Museum Of Beast Arts" src="https://beasthood.akidcalledbeast.com/assets/pfp/10art.png" />, "Museum Of Beast Arts", items.moba),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Cortado Cafe" src="https://beasthood.akidcalledbeast.com/assets/pfp/15hipster.png" />, "Cortado Cafe", items.cortadoCafe),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Faded Heights" src="https://beasthood.akidcalledbeast.com/assets/pfp/9baddie.png" />, "Faded Heights", items.fadedHeights),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Ramone Alley" src="https://beasthood.akidcalledbeast.com/assets/pfp/2punk.png" />, "Ramone Alley", items.ramoneAlley),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="L33T Labs" src="https://beasthood.akidcalledbeast.com/assets/pfp/6nerd.png" />, "L33T Labs", items.l33tLabs),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Gaia Grove" src="https://beasthood.akidcalledbeast.com/assets/pfp/16hippie.png" />, "Gaia Grove", items.gaiaGrove),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Concrete Jungle" src="https://beasthood.akidcalledbeast.com/assets/pfp/12rebel.png" />, "Concrete Jungle", items.concreteJungle),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Michelin Markets" src="https://beasthood.akidcalledbeast.com/assets/pfp/20foodie.png" />, "Michelin Markets", items.michelinMarkets),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="The Odeon" src="https://beasthood.akidcalledbeast.com/assets/pfp/11drama.png" />, "The Odeon", items.theOdeon)
    ].sort((a, b) => b.floor - a.floor);
    return isLoading ? (
        <p>Loading</p>
    ) : (
        <TableContainer style={{ width: "95vw", margin: "0px auto", maxWidth: 600, border: "none" }}>
            <Table aria-label="simple table" options={{
                sort: true,
                sortOrder: { name: 'floor', direction: 'desc' },
            }}>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>Beasthood</StyledTableCell>
                        <StyledTableCell align="right">Floor</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell><div style={{ margin: "0px !important" }}>
                                <div className='avatarNamePair'>
                                    {row.image}
                                </div>
                                <div className='avatarNamePair' style={{ paddingLeft: 10 }}>
                                    {row.name}
                                </div>
                            </div></StyledTableCell>
                            <StyledTableCell align="right" style={{ paddingRight: "18px" }}>

                                {row.floor}
                                {row.floor !== "No Listings" &&
                                    " ETH"
                                }
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default Beasthood;