import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';

function createData(image, name, floor, banner, opensea) {
    return { image, name, floor, banner, opensea };
}

const StyledTableRow = styled(TableRow)(() => ({}));
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        border: "none",
        letterSpacing: "3px",
        paddingLeft: 0,
        paddingRight: 5

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        fontFamily: "Swiss-Roman",
        letterSpacing: "1.5px",
        backgroundColor: "#e8e6e3",
        border: "none",
        borderTop: "1px #39393969 solid",
        paddingLeft: "0px"
    },
}));

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <StyledTableRow key={row.name}>
                <StyledTableCell className="arrowIcon" style={{padding: "0px", width: 25}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        style={{width: 25}}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell><div style={{ margin: "0px !important" }}>
                    <div className='avatarNamePair'>
                        {row.image}
                    </div>
                    <div className='avatarNamePair' style={{ paddingLeft: 5, minWidth: "160px" }}>
                        <p>{row.name}</p>
                    </div>
                </div></StyledTableCell>
                <StyledTableCell align="right" style={{ paddingRight: "5px" }}>
                    {row.floor}
                    {row.floor !== "No Listings" &&
                        " ETH"
                    }
                </StyledTableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                        <Button variant="outlined" href={row.opensea} target="_blank"  style={{margin: 5, color: "#393939", border: "1px #393939 solid", fontFamily: "Swiss-Roman", letterSpacing: "3px"}}>Buy On OpenSea</Button>
                        <Button variant="outlined" href={row.banner} target="_blank"  style={{margin: 5, color: "#393939", border: "1px #393939 solid", fontFamily: "Swiss-Roman", letterSpacing: "3px"}}>Banners</Button>
                        <Button variant="outlined" href="https://beasthood.akidcalledbeast.com/" target="_blank"  style={{margin: 5, color: "#393939", border: "1px #393939 solid", fontFamily: "Swiss-Roman", letterSpacing: "3px"}}>Learn More</Button>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function RichBeasthoodTable({ items, isLoading }) {
    const rows = [
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Wagmi Way" src="https://beasthood.akidcalledbeast.com/assets/pfp/3degen.png" />, "Wagmi Way", items.wagmiWay, "https://akidcalledbeast.webflow.io/banners#degens", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Wagmi%20Way&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Silicon Alley" src="https://beasthood.akidcalledbeast.com/assets/pfp/18hacker.png" />, "Silicon Alley", items.siliconAlley, "https://akidcalledbeast.webflow.io/banners#hackers", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Silicon%20Alley&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Bullhattan District" src="https://beasthood.akidcalledbeast.com/assets/pfp/1business.png" />, "Bullhattan District", items.bullhattanDistrict, "https://akidcalledbeast.webflow.io/banners#business", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Bullhattan%20District&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Nowhere Parks" src="https://beasthood.akidcalledbeast.com/assets/pfp/13loner.png" />, "Nowhere Parks", items.nowhereParks, "https://akidcalledbeast.webflow.io/banners#loners", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Nowhere%20Parks&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Moo Moo Meadows" src="https://beasthood.akidcalledbeast.com/assets/pfp/19wild.png" />, "Moo Moo Meadows", items.mooMooMeadows, "https://akidcalledbeast.webflow.io/banners#wild", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Moo%20Moo%20Meadows&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Champion's Court" src="https://beasthood.akidcalledbeast.com/assets/pfp/4athlete.png" />, "Champion's Court", items.championsCourt, "https://akidcalledbeast.webflow.io/banners#athletes", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Champions%20Court&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Fort Flex" src="https://beasthood.akidcalledbeast.com/assets/pfp/7hustler.png" />, "Fort Flex", items.fortFlex, "https://akidcalledbeast.webflow.io/banners#go-getters", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Fort%20Flex&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Cloudy Gardens" src="https://beasthood.akidcalledbeast.com/assets/pfp/14daydreamer.png" />, "Cloudy Gardens", items.cloudyGardens, "https://akidcalledbeast.webflow.io/banners#daydreamers", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Cloudy%20Gardens&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Trusty Lake Estates" src="https://beasthood.akidcalledbeast.com/assets/pfp/8preppy.png" />, "Trusty Lake Estates", items.trustyLakeEstates, "https://akidcalledbeast.webflow.io/banners#preps", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Trusty%20Lake%20Estates&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Beat Street" src="https://beasthood.akidcalledbeast.com/assets/pfp/5musician.png" />, "Beat Street", items.beatStreet, "https://akidcalledbeast.webflow.io/banners#musicians", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Beat%20Street&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Studio 66" src="https://beasthood.akidcalledbeast.com/assets/pfp/17popular.png" />, "Studio 66", items.studio66, "https://akidcalledbeast.webflow.io/banners#populars", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Studio%2066&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Museum Of Beast Arts" src="https://beasthood.akidcalledbeast.com/assets/pfp/10art.png" />, "Museum Of Beast Arts", items.moba, "https://akidcalledbeast.webflow.io/banners#artists", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=MoBA%20%28Museum%20of%20Beast%20Arts%29&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Cortado Cafe" src="https://beasthood.akidcalledbeast.com/assets/pfp/15hipster.png" />, "Cortado Cafe", items.cortadoCafe, "https://akidcalledbeast.webflow.io/banners#hip", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Cortado%20Cafe&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Faded Heights" src="https://beasthood.akidcalledbeast.com/assets/pfp/9baddie.png" />, "Faded Heights", items.fadedHeights, "https://akidcalledbeast.webflow.io/banners#baddies", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Faded%20Heights&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Ramone Alley" src="https://beasthood.akidcalledbeast.com/assets/pfp/2punk.png" />, "Ramone Alley", items.ramoneAlley, "https://akidcalledbeast.webflow.io/banners#punks", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Ramone%20Alley&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="L33T Labs" src="https://beasthood.akidcalledbeast.com/assets/pfp/6nerd.png" />, "L33T Labs", items.l33tLabs, "https://akidcalledbeast.webflow.io/banners#brains", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=L33T%20Labs&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Gaia Grove" src="https://beasthood.akidcalledbeast.com/assets/pfp/16hippie.png" />, "Gaia Grove", items.gaiaGrove, "https://akidcalledbeast.webflow.io/banners#free-spirits", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Gaia%20Grove&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Concrete Jungle" src="https://beasthood.akidcalledbeast.com/assets/pfp/12rebel.png" />, "Concrete Jungle", items.concreteJungle, "https://akidcalledbeast.webflow.io/banners#rebels", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Concrete%20Jungle&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="Michelin Markets" src="https://beasthood.akidcalledbeast.com/assets/pfp/20foodie.png" />, "Michelin Markets", items.michelinMarkets, "https://akidcalledbeast.webflow.io/banners#foodies", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=Michelin%20Markets&search[toggles][0]=BUY_NOW"),
        createData(<Avatar sx={{ width: 56, height: 56 }} style={{ margin: "0px", border: "none", background: "none" }} alt="The Odeon" src="https://beasthood.akidcalledbeast.com/assets/pfp/11drama.png" />, "The Odeon", items.theOdeon, "https://akidcalledbeast.webflow.io/banners#dramatics", "https://opensea.io/collection/akidcalledbeast?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Beasthood&search[stringTraits][0][values][0]=The%20Odeon&search[toggles][0]=BUY_NOW")
    ].sort((a, b) => b.floor - a.floor);
    return isLoading ? (
        <p>Loading</p>
    ) : (
        <TableContainer style={{ width: "95vw", margin: "0px auto", maxWidth: 600, border: "none" }}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Beasthood</StyledTableCell>
                        <StyledTableCell align="right">Floor</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}